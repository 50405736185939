import React from 'react';

import './App.css';
import './assets/styles/bootstrap-grid.min.css';
import './assets/styles/bootstrap-utilities.min.css';
import './assets/styles/main.scss';
import Navigator from './modules/navigation/components/Navigator';

function App() {
  return (<Navigator />);
}

export default App;
