import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import ReactPixel from 'react-facebook-pixel';
import { useLocation } from 'react-router-dom';
import { IS_PRODUCTION } from '../../config/env';

export default function UserTracking() {
  const location = useLocation();

  useEffect(() => {
    if (IS_PRODUCTION) {
      TagManager.initialize({
        gtmId: 'GTM-PHDN2WVD',
      });

      ReactPixel.init(
        '1619215655638017',
        undefined,
        {
          autoConfig: true,
          debug: false,
        },
      );
    }
  }, []);

  useEffect(() => {
    if (IS_PRODUCTION) {
      ReactPixel.pageView();
    }
  }, [location.pathname]);

  return null;
}
