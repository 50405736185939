import {
  Card, Modal, notification, Progress,
} from 'antd';
import { CloseOutlined, LeftOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useState } from 'react';
import SimpleBar from 'simplebar-react';
import api, {
  Conversation,
  ConversationIntent,
  ConversationIntentStatus,
  WebsiteUser,
} from '../../../api';
import useAgents from '../../hooks/useAgents';
import Select from '../../../common/components/Select';
import UserAvatar from '../../../common/components/UserAvatar';
import styles from './index.module.scss';
import Button from '../../../common/components/Button';
import ConversationIntentForm from '../ConversationIntentForm';
import ConversationInfoIntentCard from '../ConversationInfoIntentCard';
import useRequest from '../../../common/hooks/useRequest';
import useQuery from '../../../common/hooks/useQuery';
import RightSidebarSection from '../../../common/components/RightSidebarSection';
import { getDisplayTime } from '../ConversationPreviewCard';

interface ConversationInfoProps {
  conversation: Conversation;
  onIntentCreated: (intent: ConversationIntent) => void;
  onIntentUpdated: (intent: ConversationIntent) => void;
  onIntentRemoved: (intent: ConversationIntent) => void;
  onGoBackClick?: () => void;
}

export default function ConversationInfo(props: ConversationInfoProps) {
  const {
    conversation, onIntentCreated, onIntentUpdated, onIntentRemoved, onGoBackClick,
  } = props;
  const agents = useAgents();

  const [intentEditorOpen, setIntentEditorOpen] = useState(false);
  const [editIntent, setEditIntent] = useState<ConversationIntent>();
  const [deleteIntentOpen, setDeleteIntentOpen] = useState(false);
  const [deleteIntent, setDeleteIntent] = useState<ConversationIntent>();

  const totalIntents = conversation.intents?.length || 0;
  let closedIntents = 0;

  conversation.intents?.forEach((intent) => {
    if (intent.status === ConversationIntentStatus.CLOSED) {
      closedIntents += 1;
    }
  });

  const deleteIntentRequest = useRequest(async () => {
    if (!deleteIntent) {
      throw new Error('Intent not detected');
    }

    return api.conversationIntents.remove(deleteIntent?._id);
  }, {
    onSuccess: (result) => {
      onIntentRemoved(result);
      notification.success({
        message: 'Intent removed',
      });
      setDeleteIntentOpen(false);
      setDeleteIntent(undefined);
    },
  });

  const query = useQuery<WebsiteUser[]>(
    async () => api.conversations.getUsers(conversation._id),
    [conversation._id],
  );

  return (
    <>
      <div className={styles.header}>
        <div className="w-100">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center gap-2">
              <Button
                className="mobile-only"
                onClick={onGoBackClick}
                size="small"
                type="text"
                shape="circle"
              >
                <LeftOutlined />
              </Button>
              <p className="m-0 p-0">
                Assignee
              </p>
            </div>
            <Select
              placeholder="Select assignee"
              options={[
                {
                  label: 'Unassigned',
                  value: null,
                  icon: (
                    <UserAvatar size={20}>
                      U
                    </UserAvatar>
                  ),
                },
                ...agents.map((agent) => ({
                  label: agent.name,
                  value: agent._id,
                  icon: (
                    <UserAvatar avatar={agent.avatar} size={20}>
                      {agent.name[0]}
                    </UserAvatar>
                  ),
                })),
              ]}
              value={conversation.assigneeId || null}
              variant="borderless"
              popupMatchSelectWidth={false}
              size="small"
              showSearch
              disabled
            />
          </div>
        </div>
      </div>
      <SimpleBar className={styles.content}>
        <RightSidebarSection
          title="Intents"
          leftActions={(
            <Button
              size="small"
              shape="circle"
              type="link"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIntentEditorOpen(true);
              }}
            >
              <PlusCircleOutlined />
            </Button>
          )}
          rightActions={totalIntents > 0 && (
            <Progress
              percent={Math.ceil((closedIntents / totalIntents) * 100)}
              steps={totalIntents}
            />
          )}
        >
          {
            intentEditorOpen && (
              <Card
                className="mb-2"
                size="small"
                title="Create Intent"
                extra={(
                  <Button
                    size="small"
                    icon={<CloseOutlined />}
                    type="text"
                    onClick={() => setIntentEditorOpen(false)}
                  />
                )}
              >
                <ConversationIntentForm
                  conversationId={conversation._id}
                  onSuccess={(intent) => {
                    onIntentCreated(intent);
                    setIntentEditorOpen(false);
                  }}
                />
              </Card>
            )
          }
          {
            conversation.intents?.length ? (
              <>
                {
                  conversation.intents.map((intent) => {
                    const isEdited = editIntent && editIntent._id === intent._id;

                    if (isEdited) {
                      return (
                        <div className={styles.intent}>
                          <Card
                            title="Edit Intent"
                            size="small"
                            extra={(
                              <Button
                                size="small"
                                icon={<CloseOutlined />}
                                type="text"
                                onClick={() => setEditIntent(undefined)}
                              />
                            )}
                          >
                            <ConversationIntentForm
                              conversationId={conversation._id}
                              intent={intent}
                              onSuccess={(result) => {
                                onIntentUpdated(result);
                                setEditIntent(undefined);
                              }}
                            />
                          </Card>
                        </div>
                      );
                    }

                    return (
                      <ConversationInfoIntentCard
                        key={intent._id}
                        summary={intent.summary}
                        module={intent.module}
                        submodule={intent.submodule}
                        resolution={intent.resolution}
                        status={intent.status}
                        onEditClick={() => {
                          setEditIntent(intent);
                        }}
                        onDeleteClick={() => {
                          setDeleteIntentOpen(true);
                          setDeleteIntent(intent);
                        }}
                      />
                    );
                  })
                }
              </>
            ) : (
              <p className="text-muted m-0 p-0 text-center">
                No intents found
              </p>
            )
          }
        </RightSidebarSection>
        <RightSidebarSection title="Users">
          {
            query.data && query.data.length && (
              <>
                {
                  query.data.map((user) => (
                    <>
                      <div className="d-flex align-items-center justify-content-between">
                        <span>
                          {user.firstName}
                          {' '}
                          {user.lastName}
                        </span>
                        <span>{user.email}</span>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <span>Last Seen</span>
                        <span>{getDisplayTime(new Date(user.lastSeenAt))}</span>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <span>Company</span>
                        <span>
                          {user.company?.name || user.company?.id || 'Unknown'}
                        </span>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <span>MRR</span>
                        <span>
                          {user.company?.mrr ? Number(user.company.mrr).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'usd',
                          }) : 'Unknown'}
                        </span>
                      </div>
                      {
                        user.location?.country && (
                          <div className="d-flex align-items-center justify-content-between">
                            <span>Location</span>
                            <span className="d-flex align-items-center gap-2">
                              {
                                user.location.country && (
                                  <span>
                                    {
                                      user.location.city && `${user.location.city}, `
                                    }
                                    {user.location.country}
                                  </span>
                                )
                              }
                              {
                                user.location.countryCode && (
                                  <img
                                    alt="Country Flag"
                                    src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${user.location.countryCode}.svg`}
                                    height={13}
                                    className="rounded-1"
                                  />
                                )
                              }
                            </span>
                          </div>
                        )
                      }
                    </>
                  ))
                }
              </>
            )
          }
        </RightSidebarSection>
      </SimpleBar>
      <Modal
        title="Are you sure?"
        open={deleteIntentOpen}
        onCancel={() => {
          setDeleteIntentOpen(false);
          setTimeout(() => {
            setDeleteIntent(undefined);
          });
        }}
        okText="Remove Intent"
        okButtonProps={{
          loading: deleteIntentRequest.loading,
          disabled: deleteIntentRequest.loading,
        }}
        onOk={deleteIntentRequest.submit}
      >
        By removing this intent you will remove the training data for it, as well as the solution,
        and any other data that might help our system provide solutions for similar
        intents in the future.
      </Modal>
    </>
  );
}
