import Assistant, { AssistantData } from '../models/Assistant';
import BaseService from './BaseService';
import Paginated from '../types/Paginated';

type ServerAssistantData = Omit<AssistantData, 'avatar'> & { avatar?: File; };

export default class Assistants extends BaseService {
  async create(data: ServerAssistantData): Promise<Assistant> {
    return this._post<ServerAssistantData, Assistant>('/assistants', data);
  }

  async list(websiteId: Assistant['websiteId']): Promise<Paginated<Assistant>> {
    return this._get<Paginated<Assistant>>('/assistants', { params: { websiteId } });
  }

  async patch(id: Assistant['_id'], data: Partial<Omit<ServerAssistantData, 'websiteId'>>): Promise<Assistant> {
    const { avatar, ...payload } = data;

    if (avatar) {
      await this._patch(
        `/assistants/${id}/multimedia`,
        { avatar },
        { headers: { 'Content-Type': 'multipart/form-data' } },
      );
    }

    return this._patch<Partial<Omit<ServerAssistantData, 'websiteId'>>, Assistant>(`/assistants/${id}`, payload);
  }
}
